<template>
  <AppProvider>
    <NuxtPage />
  </AppProvider>
</template>

<script setup lang="ts">
import type { StoreWithDomain } from './server/routes/nuxt-api/domains.get'
const host = useHost()
const { data } = await useFetch<StoreWithDomain[]>('/nuxt-api/domains')
const domains = data.value?.map((el) => el.domain) || []
if (
  !domains?.find((el) => el.replace('www.', '') === host.replace('www.', ''))
) {
  createError({ url: '/', message: 'page_not_found', statusCode: 404 })
}
</script>

<style>
.app-enter-active,
.app-leave-active {
  transition: all 0.2s;
}
.app-enter-from,
.app-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
