import { getRequestHost, getRequestHeader } from 'h3'
import type { Store } from '~~/composables/useMenuModel'
export const useHost = () => {
  let host = ''
  const nuxtApp = useNuxtApp()
  if (process.env.NODE_ENV === 'development') {
    host = useDomain()
  } else if (process.server && nuxtApp.ssrContext) {
    host =
      getRequestHeader(nuxtApp.ssrContext.event, 'x-forwarded-host') ||
      getRequestHost(nuxtApp.ssrContext.event)
  } else if (process.client && window) {
    host = window.location.host
  }
  return host
}
export const useDomainHost = () => {
  const host = useHost()

  return {
    url: 'https://' + host.replace('www.', ''),
    host: host.replace('www.', '')
  }
}

export const useWhatsappNumer = (isLoungePhone?: boolean, store?: Store) => {
  let phone = store?.whats_app_number || store?.lounge_phone
  if (isLoungePhone && store?.lounge_phone) {
    phone = store!.lounge_phone
  }
  return phone
}

export const useDomainState = () => {
  const store = useState<Store | null>('domain_store', () => null)

  const setStore = (data: Store | null) => (store.value = data)
  const editUserHaveTable = (isUserHaveTable?: boolean) => {
    if (store.value) {
      store.value!.is_user_have_table = isUserHaveTable
    }
  }

  return {
    store,
    editUserHaveTable,
    setStore
  }
}

export const useRouteMaterial: any = (material: Material) => {
  return {
    name: 'materials-id',
    params: { id: material.id.toString() }
  }
}
