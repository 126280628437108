const debug = false

const prefix = '@@@debug@@@'

export default defineNuxtPlugin((nuxtApp) => {
  if (!debug) { return }

  nuxtApp.hooks.hook('app:created', (_app) => {
    console.log(prefix, '[APP CREATED]')
  })

  nuxtApp.hooks.hook('app:rendered', (_app) => {
    console.log(prefix, '[APP RENDERED]')
  })

  nuxtApp.hooks.hook('app:mounted', (_app) => {
    console.log(prefix, '[APP MOUNTED]')
  })

  nuxtApp.hooks.hook('app:error', (error) => {
    console.log(prefix, '[APP ERROR]', error)
  })

  nuxtApp.hooks.hook('vue:setup', () => {
    console.log(prefix, '[VUE SETUP]')
  })

  nuxtApp.hooks.hook('vue:error', (error) => {
    console.log(prefix, '[VUE ERROR]', error)
  })
})
