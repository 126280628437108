import getAllRoutes from './AppApiRoutes'
export const useSettings = () => {
  const settings = useState<GlobalSettings>('settings', () => ({}))

  const setSettings = (data: GlobalSettings) => (settings.value = data)

  return {
    settings,
    setSettings
  }
}

export const useGroupSettings = async (group: number, key: string) => {
  const { data } = await useBasicFetch<ApiResponse<any>>(
    getAllRoutes().settingsRoutes.getSettingsWithGroupByServer,
    { query: { group, domain: useDomainHeader() } }
  )
  if (data.value) {
    return data.value[key] as any
  }
}
export const useAnnouncements = () => {
  const ads = useState<Ad[]>('announcements', () => [])

  const setAds = (data: Ad[]) => (ads.value = data)
  function goToRelatedAd(ad: Ad) {
    const router = useRouter()
    const localePath = useLocalePath()

    if (ad.related_id) {
      if (ad.related_type === 'App\\Models\\Material') {
        router.push(
          localePath({
            name: 'materials-id',
            params: { id: ad.related_id }
          })
        )
      } else if (ad.related_type === 'App\\Models\\Store') {
        router.push(
          localePath({
            name: 'stores-id',
            params: { id: ad.related_id }
          })
        )
      }
    } else if (ad.link) {
      window.open(ad.link, '_blank')?.focus()
    }
  }
  return {
    ads,
    setAds,
    goToRelatedAd
  }
}

export const useStoresSettings = () => {
  const settingsStore = useState<StoresSetting[]>('StoresSetting', () => [])
  function getSettingsByKey(key: string) {
    const sittingTemp = settingsStore.value.find((el) => el.key === key)
    return sittingTemp?.pivot?.value.trim()
  }
  async function fetchSettingsStore(store: Store) {
    const { data } = await useBasicFetch<StoresSetting[]>(
      getAllRoutes().settingsRoutes.getSettingsStoreByServer,
      {
        query: {
          storeId: store?.id,
          domain: useDomainHeader(),
          group: 17
        }
      }
    )
    if (data.value?.length) {
      data.value?.forEach((el) => {
        if (el?.pivot?.value.trim() && !settingsStore.value?.includes(el)) {
          settingsStore.value.push(el)
        }
      })
    }
  }
  return {
    settingsStore,
    fetchSettingsStore,
    getSettingsByKey
  }
}



export const useMaterialDefinitionsStore = () => {
  const materialDefinitions = useState<MaterialDefinition[]>(
    'MaterialDefinitionsStore',
    () => []
  )
  async function fetchMaterialDefinitionsStore(store: Store) {
    const { data } = await useBasicFetch<SuccessResponse<MaterialDefinition[]>>(
      getAllRoutes().materialsRoutes.getMterialDefinitions,
      {
        query: {
          type_id: store?.type_id,
          limit: 1000,
          domain: useDomainHeader()
        }
      }
    )

    if (data.value?.data?.length) {
      const { materialDfinitionsModel } = useMenuModel()
      materialDefinitions.value =
        materialDfinitionsModel(data.value?.data) ?? []
    }
  }
  return {
    materialDefinitions,
    fetchMaterialDefinitionsStore
  }
}
